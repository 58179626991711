<template>
  <div class="app-container">
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="等级" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.level }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">普通等级</span>
          <span v-else-if="scope.row.type == 2">已购等级</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="所需积分/所需费用" min-width="140" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">{{ scope.row.integral }}积分</span>
          <span v-else>{{ scope.row.money }}元</span>
        </template>
      </el-table-column>
      <el-table-column label="满XX积分可抵扣XX" min-width="160" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.least_price <= 0">无门槛抵扣{{scope.row.discount_integral}}积分</span>
          <span v-else>满{{scope.row.least_price}}元抵扣{{scope.row.discount_integral}}积分</span>
        </template>
      </el-table-column>
      <el-table-column label="专属外卖折扣" min-width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.discount_waimai > 0">{{scope.row.discount_waimai}}折</span>
          <span v-else>不打折</span>
        </template>
      </el-table-column>
      <el-table-column label="专属商城折扣" min-width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.discount_shegou > 0">{{scope.row.discount_shegou}}折</span>
          <span v-else>不打折</span>
        </template>
      </el-table-column>
      <el-table-column label="赠送优惠券" min-width="140" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_num > 0">{{scope.row.coupon_value}}元*{{scope.row.coupon_num}}</span>
          <span v-else>×</span>
        </template>
      </el-table-column>
      <el-table-column label="专属餐柜" min-width="80" align="center">
        <template slot-scope="scope">
          {{scope.row.has_device ? '√' : '×'}}
        </template>
      </el-table-column>
      <el-table-column label="兑换积分商城" min-width="100" align="center">
        <template slot-scope="scope">
          {{scope.row.has_integral_exchange ? '√' : '×'}}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="100" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="50%">
      <el-form ref="form" :model="form" label-position="left" label-width="160px">
        <el-form-item label="等级">
          <el-input :disabled="true" v-model="form.level"/>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item v-if="form.type == 1" label="所需积分">
          <el-input type="number" v-model="form.integral"/>
        </el-form-item>
        <el-form-item v-if="form.type == 2" label="价格">
          <el-input type="number" v-model="form.money"/>
        </el-form-item>
        <el-form-item label="积分使用门槛">
          <el-input type="number" v-model="form.least_price" style="width:250px;margin-right:10px">
            <template slot="prepend">订单满</template>
            <template slot="append">元</template>
          </el-input>
          <el-input type="number" v-model="form.discount_integral" style="width:250px">
            <template slot="prepend">可抵扣</template>
            <template slot="append">积分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="专享外卖折扣">
          <el-input type="number" v-model="form.discount_waimai">
            <template slot="append">折</template>
          </el-input>
        </el-form-item>
        <el-form-item label="专享商城折扣">
          <el-input type="number" v-model="form.discount_shegou">
            <template slot="append">折</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 2" label="赠送优惠券">
          <el-input type="number" v-model="form.coupon_num" style="width:250px;margin-right:10px">
            <template slot="append">张</template>
          </el-input>
          <el-input type="number" v-model="form.coupon_value" style="width:250px;">
            <template slot="prepend">无门槛</template>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="专享餐柜">
          <el-switch v-model="form.has_device" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="积分兑换">
          <el-switch v-model="form.has_integral_exchange" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      btnLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
      },
      dialogVisible: false,
      form: {
        id: '',
        type: 0,
        level: 1,
        name: '',
        integral: 0,
        money: 0,
        least_price: 0,
        discount_integral: 0,
        discount_waimai: 0,
        discount_shegou: 0,
        coupon_num: 0,
        coupon_value: 0,
        has_device: 0,
        has_integral_exchange: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {},
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/memberLevel/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        type: item.type,
        level: item.level,
        name: item.name,
        integral: item.integral,
        money: item.money,
        least_price: item.least_price,
        discount_integral: item.discount_integral,
        discount_waimai: item.discount_waimai,
        discount_shegou: item.discount_shegou,
        coupon_num: item.coupon_num,
        coupon_value: item.coupon_value,
        has_device: item.has_device,
        has_integral_exchange: item.has_integral_exchange,
      };
      this.dialogVisible = true;
    },
    saveData() {
      if (!this.form.name) {
        this.$message({
          type: "warning",
          message: "请输入名称"
        });
        return;
      }
      if (this.form.type == 1) {
        if (this.form.integral === '' || this.form.integral < 0) {
          this.$message({
            type: "warning",
            message: "请输入正确的积分"
          });
          return;
        }
      }
      if (this.form.type == 2) {
        if (this.form.money === '' || this.form.money < 0) {
          this.$message({
            type: "warning",
            message: "请输入正确的价格"
          });
          return;
        }
      }
      if (this.form.least_price === '' || this.form.least_price < 0 || this.form.discount_integral === '' || this.form.discount_integral < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的积分使用门槛"
        });
        return;
      }
      if (this.form.discount_waimai === '' || this.form.discount_waimai < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的专享外卖折扣"
        });
        return;
      }
      if (this.form.discount_shegou === '' || this.form.discount_shegou < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的专享商城折扣"
        });
        return;
      }
      if (this.form.type == 2) {
        if (this.form.coupon_num === '' || this.form.coupon_num < 0) {
          this.$message({
            type: "warning",
            message: "请输入正确的赠送优惠券"
          });
          return;
        }
        if (this.form.coupon_num > 0 && (this.form.coupon_value === '' || this.form.coupon_value <= 0)) {
          this.$message({
            type: "warning",
            message: "请输入正确的赠送优惠券"
          });
          return;
        }
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/memberLevel/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.custom-inline-form {
  margin-bottom: 10px;
  .el-row {
    padding: 4px 0;
    font-weight: bold;
  }
  .el-form-item {
    margin-bottom: 4px !important;
  }
  .el-form-item__label {
    line-height: 20px !important;
  }
  .el-form-item__content {
    line-height: 20px !important;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
